/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import sortBy from 'lodash/sortBy'
import { Card } from '@myob/myob-widgets'
import LatestBill from '../MyBills/components/LatestBill'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import { EActionNames, trackClickEventInMyBills } from './myBillsHelper'
import { EHttpStatusCode } from 'helpers/type'
import BillingHistory from '../MyBills/components/BillingHistory'
import { IBill, IBillsState } from '../MyBills'
import { ETableFields } from './components/BillingHistory/helper'
import isEmpty from 'lodash/isEmpty'
import ErrorComponent from 'components/ErrorComponent'
import { Unauthorised } from 'components/Unauthorised'
import { EPage } from 'type'
import NextBillsTable from './components/NextBill'
import { fetchPastDueInvoicesByClientId } from '../../stores/reducers/arrears'
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account'
import ArrearsBanner from 'components/ArrearsBanner/ArrearsBanner'
import { ETelemetryNames } from 'telemetry/type'
import { sendGtmButtonEvent } from '../../helpers/ga'

export type TSortState = {
    activeSort: {
        column?: string
        descending?: boolean
    }
    data: IBill[]
}

const sortBills = (field: ETableFields, data: IBill[], descending: boolean) => {
    let result = data
    switch (field) {
        case ETableFields.DateIssued:
            result = sortBy(data, (bill) => bill.invoiceDate)
            break
        case ETableFields.BillingPeriod:
            result = sortBy(data, (bill) => bill.billingStartDate)
            break
        case ETableFields.TotalAmount:
            result = sortBy(data, (bill) => Number(bill.totalAmount))
            break
        case ETableFields.TotalDue:
            result = sortBy(data, (bill) => Number(bill.totalDue))
            break
        case ETableFields.Status:
            result = sortBy(data, (bill) => bill.status)
            break
    }
    return descending ? result.filter(bill => bill.totalAmount !== 577).reverse() : result.filter(bill => bill.totalAmount !== 577)
}

export const Content: React.FC<{
    fetchMore(page: number): void
    monthDropdownInitDate?: string
    isInvalidPaymentProfile?: boolean
}> = ({ fetchMore, monthDropdownInitDate, isInvalidPaymentProfile }) => {
    const {
        history: billHistory,
        next: nextBills,
        latest,
        error,
        isError,
        isNextError,
        isHistoryUsagesLoading,
        selectedDate,
    } = useSelector<RootState, IBillsState>((state) => state.bills)

    const {
        data,
        meta: { totalRecords },
        isLoading: billHistoryLoading,
    } = billHistory

    const shouldShowBtn = totalRecords > data.length + 1

    const initialState: TSortState = useMemo(
        () => ({
            activeSort: {},
            data,
        }),
        [data]
    )
    const dispatch = useDispatch()
    const { selected, isSoloAccount } = useContext<AccountSelectContextValue>(AccountSelectContext)
    const clientId = selected?.clientId
    const [sortState, setSortState] = useState<TSortState>(initialState)
    useEffect(() => {
        dispatch(fetchPastDueInvoicesByClientId(clientId))
    }, [clientId, dispatch])

    useEffect(() => {
        setSortState({
            activeSort: {},
            data,
        })
    }, [data])

    const onSort = useCallback(
        (column: ETableFields) => {
            const nextSortOrder =
                sortState.activeSort.column !== column || sortState.activeSort.descending == null
                    ? column !== ETableFields.DateIssued
                    : !sortState.activeSort.descending
            setSortState({
                activeSort: { column, descending: nextSortOrder },
                data: sortBills(column, data, nextSortOrder),
            })
        },
        [data, sortState.activeSort]
    )

    const loadMore = useCallback(() => {
        fetchMore(billHistory.meta.page + 1)
        trackClickEventInMyBills(EActionNames.LoadMore, 'BillingHistory')
        sendGtmButtonEvent('mybills', 'load more')
        setSortState({
            activeSort: {},
            data,
        })
    }, [billHistory.meta.page, data, fetchMore])

    if (isError || isNextError) {
        if (error?.status === EHttpStatusCode.FORBIDDEN) {
            return (
                <Unauthorised
                    pageName={EPage.AccountViewMyBills}
                    region={latest?.region}
                    type={error.type}
                    additionalInfo={error.additional_info}
                />
            )
        }
        return <ErrorComponent parentPage="AccountViewMyBills" />
    }
    return (
        <div>
            <ArrearsBanner parentPage={ETelemetryNames.MyBills} data-testid="my-bills-banner"></ArrearsBanner>
            <div data-testid="my-bills-component">
                <Card body={<LatestBill latestBill={latest} isInvalidPaymentProfile={isInvalidPaymentProfile!} />} />
                {!isSoloAccount && monthDropdownInitDate && (
                    <NextBillsTable
                        nextCharges={nextBills}
                        selectedDate={selectedDate}
                        disableMonthSelector={isHistoryUsagesLoading}
                        initDate={monthDropdownInitDate}
                        region={latest?.region}
                    />
                )}
                <BillingHistory
                    bills={sortState.data}
                    activeSort={sortState.activeSort}
                    onSort={onSort}
                    hasLatestBill={!isEmpty(latest)}
                    loadMore={loadMore}
                    shouldShowBtn={shouldShowBtn}
                    disableLoadMoreBtn={billHistoryLoading}
                    isInvalidPaymentProfile={isInvalidPaymentProfile!}
                />
            </div>
        </div>
    )
}
